.container {
  background-color: #fffcf1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  min-width: 100vw;
}

.loading {
  z-index: 999;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  .content {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 999;

    .text {
      color: #FFF;
      font-size: 28px;
    }
  }
}



.overlay {
  background-color: #000;
  opacity: 0.8;
  z-index: 100;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
