.enterCode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    height: 100vh;
    width: 100vw;
  
    .content {
      position: relative;
      z-index: 999;
      background-color: #fffcf1;
      border-radius: 4px;
      padding: 20px;
      width: 100%;
      max-width: 450px;
  
      .heading {
        font-size: 28px;
        text-align: center;
      }
  
      .label {
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
  
      .input {
        font-size: 16px;
        text-align: center;
        padding: 12px;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-family: 'Gotham';
      }
    }

    .error {
        color: red;
        text-align: center;
        margin: 8px 0px;
        font-size: 14px;
    }
}