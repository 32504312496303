.noUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    height: 100vh;
    width: 100vw;
  
    .content {
      position: relative;
      z-index: 200;
      background-color: #fffcf1;
      border-radius: 4px;
      padding: 40px;
      width: 100%;
      max-width: 450px;
  
      .heading {
        font-size: 36px;
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
}