@font-face {
  font-family: "Gotham";
  src: url(assets/fonts/Gotham-Book_0.otf) format("opentype");
}

@font-face {
  font-family: "Amersn";
  src: url(assets/fonts/AMERSN__.ttf) format("truetype");
}

@font-face {
  font-family: "Loffers";
  src: url("assets/fonts/Loffers.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Loffers.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Loffers.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Loffers.ttf") format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Hunter";
  src: url("assets/fonts/Anthony Hunter.woff") format("woff"),
    url("assets/fonts/Anthony Hunter.otf") format("woff"),
    url("assets/fonts/Anthony Hunter.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.font-loffers {
  font-family: 'Hunter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.font-gotham {
  font-family: 'Gotham', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.font-amersn,
p {
  font-family: 'Amersn', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.primary-button {
  background-color: #3e5526;
  width: 100%;
  border: 0px;
  outline: 0;
  border-radius: 4px;
  padding: 8px;
  font-family: 'Gotham';
  font-size: 14px;
  color: #fff;
  margin-top: 16px;
  height: 40px;
  opacity: 0.95;
}

.primary-button:hover {
  cursor: pointer;
  opacity: 1;
}

.overlay {
  background-color: #000;
  opacity: 0.5;
  z-index: 100;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}