.roulette {
    margin: 0px auto;
    width: 100%;
    padding: 20px;
    max-width: 720px;
    text-align: center;

    .heading {
        font-size: 40px;
    }

    p {
        font-size: 18px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .slotMachine {
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 0px auto;
        margin-top: 40px;

        .randomVoucher {    
            font-family: 'Amersn';
            font-size: 20px;
            top: 50%;
            position: absolute;
            left: 20.5%;
            right: 21.5%;

            img {
                max-width: 100px;
                margin-top: -19%;
            }
        }

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .startButton {
        background-color: transparent;
        font-size: 18px;
        width: 100%;
        border: 0px;
        outline: 0;
        border-radius: 4px;
        padding: 8px;
        font-family: 'Amersn';
        margin-top: 16px;
        height: 40px;
        opacity: 0.95;
        border: 1px solid #3e5526;
        max-width: 250px;
        
        &:hover {
            background-color: #3e5526;
            color: #fff;
            cursor: pointer;
            opacity: 1;
        }
    }
}

@media (max-width: 720px) {
    .roulette {    
        .slotMachine {
            .randomVoucher {
                img {
                    max-width: 75px;
                }
            }
        }
    }
}